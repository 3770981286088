<template>
  <div class="container" v-title :data-title="$t('i18n.maintenanceBatch')">
    <div id="outer-title">{{ $t("i18n.maintenanceBatch") }}</div>
    <div v-loading="loading">
      <jl-table
        :tableData="tableData"
        @handleDelete="handleDelete"
        @init="init"
        :options="options"
        :columns="columns"
        :operates="operates"
        :total="total"
        :height="$defined.HEIGHT - 360 + 'px'"
      >
      </jl-table>
    </div>
    <el-dialog
      :title="$t('i18n.updateMaintenanceStaff')"
      v-model="dialogFormVisible"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <jl-form
        :columns="staffColumns"
        @onSubmit="onSubmit"
        @resetForm="resetForm"
      ></jl-form>
    </el-dialog>
  </div>
</template>
<script>
import { reactive, toRefs, getCurrentInstance } from "vue";
import { useI18n } from "vue-i18n";
import jlTable from "../../components/table";
import jlForm from "../../components/form";
export default {
  name: "MPlanBath",
  components: {
    jlTable,
    jlForm,
  },
  setup(props, { emit }) {
    emit("public_header", true);
    const { proxy } = getCurrentInstance();
    const { t } = useI18n();
    const state = reactive({
      formInline: {
        pageNumber: 1,
        pageSize: 10,
      },
      // table数据
      tableData: [],
      options: {
        // table 的参数
        isAdd: false, // 是否启用新增功能
        isEdit: false, // 是否启用编辑功能
        isDelete: proxy.$defined.btnPermission("删除维保批次"), // 是否启用删除功能
        highlightCurrentRow: false, // 是否支持当前行高亮显示
        multiSelect: false, // 是否支持列表项选中功能
        pageShow: true, // 是否翻页
      },
      columns: [
        // 需要展示的列
        {
          prop: "upkeepContractNumber",
          label: "maintenanceContractNo",
          align: "center",
          search: true,
          type: "input",
          rules: [{ required: true, message: t("i18n.input") }],
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          align: "center",
          search: true,
          type: "input",
        },
        {
          prop: "maintenanceMan",
          label: "maintenancePerson",
          align: "center",
          width: 150,
          search: true,
          type: "input",
          formatter: (row) => {
            if (proxy.$defined.btnPermission("修改维保人员")) {
              return `<a>${row.maintenanceMan}</a>`;
            } else {
              return row.maintenanceMan;
            }
          },
          method: (row) => {
            updateMaintenanceStaff(row);
          },
        },
        {
          prop: "startTime",
          label: "startTime",
          align: "center",
          formatter: (row) => {
            return row.startTime.split(" ")[0];
          },
        },
        {
          prop: "endTime",
          label: "completionDate",
          align: "center",
          formatter: (row) => {
            return row.endTime.split(" ")[0];
          },
        },
        {
          prop: "maintenanceManNow",
          label: "maintenanceCurrentPerson",
          align: "center",
          formatter: (row) => {
            return row.maintenanceManNow || row.maintenanceMan;
          },
        },
      ],
      operates: {
        // 列操作按钮
        width: 80,
        fixed: "right",
        list: [],
      },
      total: 0,
      loading: true,
      dialogFormVisible: false,
      staffColumns: [
        {
          prop: "id",
          label: "",
          show: false,
        },
        {
          prop: "upkeepContractNumber",
          label: "maintenanceContractNo",
          type: "input",
          readonly: true,
        },
        {
          prop: "elevatorNumber",
          label: "elevatorNo",
          type: "input",
          readonly: true,
        },
        {
          prop: "maintenanceManId",
          label: "maintenancePerson",
          type: "select",
          props: { label: "name", value: "id" },
          data: [],
          rules: [{ required: true, message: t("i18n.input") }],
        },
      ],
    });

    const init = async (params) => {
      state.loading = true;
      const { data } = await proxy.$api.maintenance.getBathPage(params);
      state.tableData = data.records;
      state.total = data.total;
      state.loading = false;
    };

    const handleDelete = (row, pages) => {
      proxy.$api.maintenance.delBathOne(row.id).then(() => {
        init(pages);
        proxy.$defined.tip(t("i18n.delete") + t("i18n.success"), "success");
      });
    };

    const updateMaintenanceStaff = (row) => {
      state.dialogFormVisible = true;
      state.staffColumns.map((item) => {
        item.isUpdate = true;
        item.value = row[item.prop];
      });
    };

    const onSubmit = async (form) => {
      let obj = {
        personId: form.maintenanceManId,
        bathId: form.id,
      };
      await proxy.$api.maintenance.updatePerson(obj);
      resetForm();
      init(state.formInline);
      proxy.$defined.tip(
        t("i18n.updateMaintenanceStaff") + t("i18n.success"),
        "success"
      );
    };

    const resetForm = () => {
      state.dialogFormVisible = false;
    };

    const setData = async () => {
      let { data } = await proxy.$api.maintenance.getMainPerson();
      state.staffColumns.map((item) => {
        if (item.prop === "maintenanceManId") {
          item.data = data;
        }
      });
    };

    setData();

    return {
      ...toRefs(state),
      init,
      handleDelete,
      updateMaintenanceStaff,
      onSubmit,
      resetForm,
    };
  },
};
</script>
